body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  padding-top: 60px;
}

.news {
  margin-top: 5px;
}

.news-item {
  list-style-type: none;
  margin: 10px 0;
  font-size: 16px;
}

.news-component-topic ul {
  padding-left: 0;
  margin-bottom: 20px;
}

.news-component-title {
  font-size: 18px;
  padding-right: 10px;
}

.navbar-brand {
  padding: 10px;
}

.navbar-brand img {
  float: left;
  width: 30px;
}

.navbar-brand a {
  padding-left: 10px;
  vertical-align: sub;
  color: white;
  text-decoration: none;
}

.news-component-title a {
  color: black;
  text-decoration: none;
  font-size: 22px;
}

article h3 {
  margin-top: 0;
}

article img {
  width: 345px;
}

article .subtle {
  color: lightslategrey;
}

article a {
  padding-bottom: 50px;
  font-size: 18px;
}

article .text {
  padding-top: 10px;
  font-size: 18px;
  line-height: 24px;
}

.articleLinks {
  float: right;
}

.navbar-collapse.in {
  overflow: hidden;
  max-height: none !important;
  height: auto !important;
}

.search {
  margin-bottom: 10px;
}

.moreLink {
  padding-left: 90px;
}

/* Start of always hamburger menu */
@media (min-width: 768px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }

  article .text {
    padding-right: 20px;
  }

  article .articleLinks {
    padding-right: 20px;
  }

  [data-article="false"]
  {
    padding-right: 25px;
  }

  .moreLink {
    padding-left: 260px;
  }
}
/* End of always hamburger menu */

@media (min-width: 1024px) {
  .moreLink {
    padding-left: 390px;
  }
}

/* @media (prefers-color-scheme: dark) { */
  body {
    background-color: black;
    color: #d7d7d7;
  }

  a:link {
    color: lightgreen;
  }

  a:visited {
    color: lightgreen;
  }

  a:hover {
    color: lightgreen;
  }

  a:active {
    color: lightgreen;
  }

  .navbar-brand a {
    color: white;
  }

  .news-component-title a {
    color: white;
  }

  .news-source {
    color: grey;
  }

  .news-comments a:link {
    color: grey;
  }

  .news-comments a:visited {
    color: grey;
  }

  .news-comments a:hover {
    color: grey;
  }

  .news-comments a:active {
    color: grey;
  }
/* } */
